import SDPHelpers from '../sdp_helpers';
import getMediaSectionsForAnswerDefault from './getMediaSectionsForAnswer';
import getMediaSectionsForOfferDefault from './getMediaSectionsForOffer';
import createLogger from '../../../helpers/log';

const logging = createLogger('partialSdp');

const isPartialSdp = sdp => sdp.tracks.length !== sdp.mids.length;

const createGetFullRemoteSdp = (deps = {}) => {
  const getMediaSectionsForAnswer = deps.getMediaSectionsForAnswer
    || getMediaSectionsForAnswerDefault;
  const getMediaSectionsForOffer = deps.getMediaSectionsForOffer || getMediaSectionsForOfferDefault;

  const getFullSdpFromPartial = (type, partialSdp, currentSdp) => {
    const isAnswer = type === 'answer';
    const getMediaSections = isAnswer ? getMediaSectionsForAnswer : getMediaSectionsForOffer;
    const mediaSections = getMediaSections(partialSdp, currentSdp);
    const mids = mediaSections.map(SDPHelpers.getSectionMid);
    const tracks = mids.filter(mid => mid !== null);

    let headers;
    if (isAnswer) {
      headers = partialSdp.headers;
    } else {
      // We update the headers so bundle and credentials are the correct ones. Please note bundle
      // can have a different order in Mantis and Client.
      headers = SDPHelpers.updateHeadersInPartialSdp(currentSdp, partialSdp, tracks);
    }

    return {
      headers,
      version: partialSdp.version,
      bundle: SDPHelpers.createBundleLine(tracks),
      tracks,
      mediaSections,
      mids,
      iceCredentials: partialSdp.iceCredentials,
    };
  };

  const getFullRemoteSdp = (type, newRemoteSdp, currentRemoteSdp) => {
    if (!isPartialSdp(newRemoteSdp)) {
      // This is a full SDP
      return newRemoteSdp;
    }

    logging.debug('partial remote sdp: ', newRemoteSdp);

    let partialSdp;
    if (currentRemoteSdp.bundle) {
      partialSdp = newRemoteSdp;
    } else {
      // This is a partial SDP, however it is the first one we have received (none cached yet), we
      // may need to add ICE credentials to the first enabled section.
      const { mediaSections, iceCredentials } = newRemoteSdp;
      partialSdp = Object.assign({}, newRemoteSdp,
        { mediaSections: SDPHelpers.addIceCredentials(mediaSections, iceCredentials) });
    }

    return getFullSdpFromPartial(type, partialSdp, currentRemoteSdp);
  };

  return getFullRemoteSdp;
};

export default createGetFullRemoteSdp;
